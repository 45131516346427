<template>
  <div>
    <div v-if="userProfile.id">
      <b-card>
        <validation-observer
          ref="securityTab"
        >
          <b-form>
            <b-row>
              <b-col
                sm="12"
                xl="6"
                class="col-xxl-4"
              >
                <b-form-group
                  :label="$t('old_Password')"
                  label-for="account-old-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('old_Password')"
                    vid="old_password"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-old-password"
                        v-model="form.old_password"
                        name="old-password"
                        :type="passwordFieldTypeOld"
                        :placeholder="$t('old_Password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconOld"
                          class="cursor-pointer"
                          @click="togglePasswordOld"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                xl="6"
                class="col-xxl-4"
              >
                <b-form-group
                  label-for="account-new-password"
                  :label="$t('new_Password')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('new_Password')"
                    vid="new_password"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-new-password"
                        v-model="form.password"
                        :type="passwordFieldTypeNew"
                        name="new-password"
                        :placeholder="$t('new_Password')"
                        minlength="8"
                        maxlength="16"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconNew"
                          class="cursor-pointer"
                          @click="togglePasswordNew"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                xl="6"
                class="col-xxl-4"
              >
                <b-form-group
                  label-for="account-retype-new-password"
                  :label="$t('retype_new_password')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('retype_new_password')"
                    vid="retype_password"
                    rules="required|confirmed:new_password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-retype-new-password"
                        v-model="form.password_confirmation"
                        :type="passwordFieldTypeRetype"
                        name="retype-password"
                        :placeholder="$t('retype_new_password')"
                        minlength="8"
                        maxlength="16"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRetype"
                          class="cursor-pointer"
                          @click="togglePasswordRetype"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-if="!pendingChangePassword"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  :disabled="userProfile.isActive == 0"
                  @click="changePassword"
                >
                  {{ $t('save_changes') }}
                </b-button>
                <b-button
                  v-if="pendingChangePassword"
                  variant="primary"
                  class="mt-1 mr-1"
                  disabled
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
      <b-card>
        <validation-observer
          ref="twoFactor"
        >
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-form-checkbox
                    id="setup"
                    v-model="userProfile.twoFactorAuth.is_active"
                    name="checkbox-1"
                  >
                    {{ $t('Setup') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="account-new-password"
                  :label="$t('Setup_two_factor_login_authentication')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('type')"
                    vid="type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="twoFactorType"
                      :disabled="!userProfile.twoFactorAuth.is_active"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="options"
                      label="label"
                      name="type"
                      :placeholder="$t('save_changes')"
                      :clearable="false"
                    >
                      <template
                        slot="option"
                        slot-scope="option"
                      >
                        <div v-if="option === 'sms'">
                          <span>{{ option.label }}</span>
                          <span style="margin: 0 4px; font-size: 12px;">({{ $t('suggested') }})</span>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="twoFactorType.key === 'sms'"
                cols="12"
              >
                <b-alert
                  class="p-2"
                  show
                  variant="warning"
                >
                  <p>{{ $t('two-factor-sms-warning1') }}</p>
                  <p>{{ $t('two-factor-sms-warning2') }}</p>
                </b-alert>
              </b-col>
              <b-col
                v-if="twoFactorType.key === 'telegram'"
                cols="12"
              >
                <b-alert
                  show
                  variant="warning"
                >
                  <b-card :title="$t('two-factor-telegram-card-title')">
                    <p>{{ $t('two-factor-telegram-card-description') }}</p>
                    <b-list-group>
                      <b-list-group-item>1. {{ $t('two-factor-telegram-card-step1') }}</b-list-group-item>
                      <b-list-group-item>2. {{ $t('two-factor-telegram-card-step2') }}</b-list-group-item>
                      <b-list-group-item>3. {{ $t('two-factor-telegram-card-step3') }}</b-list-group-item>
                      <b-list-group-item>4. {{ $t('two-factor-telegram-card-step4') }}</b-list-group-item>
                      <b-list-group-item>5. {{ $t('two-factor-telegram-card-step5') }}</b-list-group-item>
                    </b-list-group>
                  </b-card>
                </b-alert>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-if="!pendingTwoFactor"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  @click="saveFactor(false)"
                >
                  {{ $t('save_changes') }}
                </b-button>
                <b-button
                  v-if="pendingTwoFactor"
                  variant="primary"
                  class="mt-1 mr-1"
                  disabled
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>

        </validation-observer>
      </b-card>
      <securityPassword />
      <activeSessions />
      <!-- modals -->
      <b-modal
        v-model="confirmFormModal"
        :hide-footer="true"
        centered
        no-close-on-backdrop
      >
        <b-row>
          <b-col cols="12">
            {{ $t('otp-description',{ type:twoFactorType.label }) }}
          </b-col>
          <b-col
            cols="12"
            class="my-1"
          >
            <flip-countdown
              :label="{ hours: `${this.$t('hour')}`,
                        minutes: `${this.$t('minute')}`,
                        seconds: `${this.$t('second')}` }"
              :style="{direction:'ltr'}"
              :deadline="deadline"
              :show-days="false"
              :show-hours="false"
              @timeElapsed="timeElapsedHandler"
            />
          </b-col>
          <b-col cols="12">
            <validation-observer
              ref="verifyForm"
            >
              <form
                @submit.stop.prevent="handleOTP"
              >
                <b-form-group
                  invalid-feedback="Verification code is required"
                  label-for="verification_code"
                  :label="$t('verification_code')"
                >
                  <div id="divOuter">
                    <div id="divInner">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('verification_code')"
                        vid="verification-code"
                        rules="required"
                      >
                        <b-form-input
                          id="partitioned"
                          v-model="verifyCode"
                          maxlength="6"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                          on-key-press="if(this.value.length==4) return false;"
                          required
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </div>
                </b-form-group>
                <b-row align-h="between">
                  <b-col cols="auto">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="success"
                      :disabled="verifyCode.length < 4"
                      @click="handleOTP"
                    >
                      <span v-if="!pendingOTP">{{ $t('save') }}</span>
                      <span v-else>
                        <b-spinner
                          small
                          type="grow"
                        />
                      </span>
                    </b-button>
                  </b-col>
                  <b-col cols="auto">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      :disabled="!reSendBtn"
                      @click="saveFactor(true)"
                    >
                      <span v-if="!pendingReSendOtp">{{ $t('re-send') }}</span>
                      <span v-else>
                        <b-spinner
                          small
                          type="grow"
                        />
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
  BInputGroup, BInputGroupAppend, BFormCheckbox, BSpinner, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import FlipCountdown from 'vue2-flip-countdown'
import { required } from '@validations'
import { alphanumeric } from '@/mixins/alphanumeric'
import ProfileApis from '@/services/apis/profile'
import FormApis from '@/services/apis/form'
import axiosIns from '@/libs/axios'
import activeSessions from './activeSessions.vue'
import securityPassword from '@/views/components/securityPassword.vue'

const formData = new FormApis()
const userProfile = new ProfileApis()
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    vSelect,
    BModal,
    FlipCountdown,
    activeSessions,
    securityPassword,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      twoFactorList: [],
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      twoFactorType: '',
      pendingChangePassword: false,
      pendingTwoFactor: false,
      userProfile: {
        profile: {},
        twoFactorAuth: {
          is_active: false,
          type: '',
        },
      },
      confirmFormModal: false,
      pendingReSendOtp: false,
      pendingOTP: false,
      deadline: '',
      verifyCode: '',
      reSendBtn: false,
      required,
    }
  },
  computed: {
    profileInfo() {
      return this.$store.state.profile.profileInfo
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    options() {
      return this.twoFactorList.map(item => ({
        label: this.$t(item),
        key: item,
      }))
    },
  },
  watch: {
    profileInfo() {
      if (this.profileInfo && this.profileInfo.twoFactorAuth) {
        // eslint-disable-next-line eqeqeq
        if (this.profileInfo.twoFactorAuth.is_active == 1) {
          this.twoFactorType = {
            label: this.$t(`${this.profileInfo.twoFactorAuth.type}`),
            key: this.profileInfo.twoFactorAuth.type,
          }
        }
        this.userProfile = {
          ...this.profileInfo,
          twoFactorAuth: {
            ...this.profileInfo.twoFactorAuth,
            is_active: this.profileInfo?.twoFactorAuth?.is_active === 1,
          },
        }
      }
    },
    // eslint-disable-next-line func-names
    'userProfile.twoFactorAuth.is_active': function (val) {
      if (!val) {
        this.twoFactorType = {
          label: '',
          key: '',
        }
      }
    },
  },
  mounted() {
    userProfile.twoFactorList().then(res => {
      this.twoFactorList = res.data.results
    })
    if (this.profileInfo && this.profileInfo.twoFactorAuth) {
      // eslint-disable-next-line eqeqeq
      if (this.profileInfo.twoFactorAuth.is_active == 1) {
        this.twoFactorType = {
          label: this.$t(`${this.profileInfo.twoFactorAuth.type}`),
          key: this.profileInfo.twoFactorAuth.type,
        }
      }
      this.userProfile = {
        ...this.profileInfo,
        twoFactorAuth: {
          ...this.profileInfo.twoFactorAuth,
          is_active: this.profileInfo?.twoFactorAuth?.is_active === 1,
        },
      }
    }
  },
  methods: {
    async changePassword() {
      const success = await this.$refs.securityTab.validate()
      if (success) {
        this.pendingChangePassword = true
        userProfile.storeChangePassword(this.form).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
        }).finally(() => {
          this.pendingChangePassword = false
        })
      }
    },
    async saveFactor(isResend = false) {
      this.$refs.twoFactor.validate().then(async success => {
        if (success) {
          if (isResend) {
            this.pendingReSendOtp = true
            await this.$refs.verifyForm.reset()
          } else {
            this.pendingTwoFactor = true
          }
          const active = this.userProfile.twoFactorAuth.is_active === true ? 1 : 0
          const data = {
            is_active: (active).toString(),
            type: this.twoFactorType.key,
          }
          await userProfile.storeFactor(data).then(res => {
            this.deadline = this.getExpireTime(res.data.results.remained_time)
            if (!isResend) {
              this.confirmFormModal = true
            }
            this.$swal({
              title: res.data.message,
              icon: 'success',
              timer: 2500,
              showConfirmButton: false,
            })
          }).catch(error => {
            if (error.data?.results?.remained_time) {
              this.confirmFormModal = true
              this.deadline = this.getExpireTime(error.data?.results?.remained_time)
            }
          })
            .finally(() => {
              this.pendingTwoFactor = false
              this.pendingReSendOtp = false
            })
        }
      })
    },

    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    async handleOTP() {
      this.pendingOTP = true
      const success = await this.$refs.verifyForm.validate()
      if (success) {
        await axiosIns.post('two-f-a/verify-code', { code: this.verifyCode })
          .then(res => {
            this.confirmFormModal = false
            this.$swal({
              title: res.data.message,
              icon: 'success',
              timer: 2500,
              showConfirmButton: false,
            })
            this.confirmFormModal = false
            this.verifyCode = ''
          }).catch(error => {
            if (error.response.status === 422) {
              this.toast('Verification code is wrong', 'danger')
            }
          })
          .finally(() => {
            this.pendingOTP = false
          })
      }
    },

    getExpireTime(expireTime) {
      const currentDate = new Date()
      const msSinceEpoch = currentDate.getTime()
      const seventeenHoursLater = new Date(msSinceEpoch + expireTime * 1000)
      const currentDayOfMonth = seventeenHoursLater.getDate()
      const currentMonth = seventeenHoursLater.getMonth()
      const currentYear = seventeenHoursLater.getFullYear()
      const hours = seventeenHoursLater.getHours()
      const minutes = seventeenHoursLater.getMinutes()
      const seconds = seventeenHoursLater.getSeconds()
      const dateString = `${currentYear}-${currentMonth + 1}-${currentDayOfMonth} ${hours}:${minutes}:${seconds}`
      return dateString
    },

    timeElapsedHandler() {
      this.reSendBtn = true
    },
  },
}
</script>
